// import React, { useState, useEffect } from 'react'
// import Paper from '@material-ui/core/Paper'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TablePagination from '@material-ui/core/TablePagination'
// import TableRow from '@material-ui/core/TableRow'
// import { Collapse, Box, Grid, TextField } from '@material-ui/core'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import { Button } from '@material-ui/core'
// import { Link, useRouteMatch, useParams } from 'react-router-dom'
// import Chip from '@material-ui/core/Chip'
// import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
// import NavigateNextIcon from '@material-ui/icons/NavigateNext'
// import SearchIcon from '@material-ui/icons/Search'
// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import GetAppIcon from '@material-ui/icons/GetApp'
// import ClearIcon from '@material-ui/icons/Clear'

// import AssessmentIcon from '@material-ui/icons/Assessment'
// import { KeyboardDateTimePicker } from '@material-ui/pickers'
// import moment from 'moment'
// import axios from 'axios'
// import { MenuItem, Typography } from '@material-ui/core'

// import useStyles from './styles'
// import { getStudentMembers } from '../../api'
// import BASE_URL from '../../api/constants'
// import StudentMemberFormDialog from '../StudentMemberFormDialog/StudentMemberFormDialog'

// const columns = [
//   { id: 'chapterCode', label: 'Chapter Code' },
//   { id: 'membershipNo', label: 'Membership No' },
//   { id: 'sectionCode', label: 'Section Code' },
//   { id: 'name', label: 'Full Name' },
//   { id: 'instituteName', label: 'Institution Name' },
//   { id: 'state', label: 'Institute State' },
//   // { id: 'email', label: 'Email' },
//   { id: 'duration', label: 'Duration' },
//   // { id: 'branch', label: 'Branch' },
//   // { id: 'mobile_no', label: 'Mobile No' },
//   // { id: 'instituteId', label: 'Institute Id' },
//   // { id: 'gender', label: 'Gender' },
//   { id: 'view', label: '' },
// ]

// function GenerateReportDialog(props) {
//   const [open, setOpen] = React.useState(false)

//   const handleClickOpen = () => {
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }

//   return (
//     <div>
//       <Button variant='contained' color='primary' onClick={handleClickOpen}>
//         <AssessmentIcon /> Generate Report
//       </Button>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby='form-dialog-title'
//       >
//         <DialogTitle id='form-dialog-title'>
//           Generate Report
//           <br />
//           Total Count : {props.count}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Download Data in the .xlsx format. Since we have more than{' '}
//             {props.count} entries you can download them in batches
//           </DialogContentText>
//           {Array(Math.ceil(props.count / 100000))
//             .fill(null)
//             .map((value, idx) => (
//               <Button
//                 style={{ margin: '2px' }}
//                 variant='outlined'
//                 onClick={() => {
//                   props.generateReport(idx * 100000, (idx + 1) * 100000)
//                 }}
//               >
//                 <GetAppIcon /> Download Entries between ({idx * 100000 + 1} and{' '}
//                 {(idx + 1) * 100000})
//               </Button>
//             ))}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color='primary'>
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   )
// }
// function DownloadCardDialog(props) {
//   const [open, setOpen] = React.useState(false)
//   const [chapterInputs, setChapterInputs] = useState({})
//   const handleChapterInputChange = (e) => {
//     setChapterInputs({ ...chapterInputs, [e.target.name]: e.target.value })
//   }
//   const downloadChapterCards = async () => {
//     try {
//       axios({
//         url: `${BASE_URL}/api/student-membership/members/download-cards`, //your url
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem('profile'))?.token
//           }`,
//         },
//         params: { ...chapterInputs },
//         responseType: 'blob', // important
//       }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = url
//         link.setAttribute(
//           'download',
//           `Student Cards - downloaded at ${moment().format(
//             'YYYY-MM-DD-HH-MM-SS'
//           )}.pdf`
//         ) //or any other extension
//         document.body.appendChild(link)
//         link.click()
//       })
//     } catch (error) {
//       console.log(error)
//     }
//   }


//   const handleClickOpen = () => {
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }
//   const classes = useStyles() // Use your styles
//   return (
//     <div>
//       <Button variant='contained' color='primary' onClick={handleClickOpen}>
//         <GetAppIcon /> Download Cards
//       </Button>

//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby='form-dialog-title'
//       >
//         <DialogTitle id='form-dialog-title'>
//           Download Cards
//           <br />
//           Total Count : {props.count}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>Download Cards by Chapter Code</DialogContentText>
//           <Grid container spacing={3}>
//             <Grid item xs={12}>
//               <TextField
//                 name='chapter_code'
//                 label='Chapter Code'
//                 variant='outlined'
//                 onChange={handleChapterInputChange}
//                 size='small'
//                 required
//               ></TextField>
//             </Grid>
//             <Grid item>
//               <TextField
//                 name='startMembershipNo'
//                 label='Start Membership No'
//                 variant='outlined'
//                 size='small'
//                 onChange={handleChapterInputChange}
//                 required
//               ></TextField>
//             </Grid>
//             <Grid item>
//               <TextField
//                 name='endMembershipNo'
//                 label='End Membership No'
//                 variant='outlined'
//                 size='small'
//                 onChange={handleChapterInputChange}
//                 required
//               ></TextField>
//             </Grid>
//             <Grid item>
//               <Button
//                 style={{ margin: '3px' }}
//                 variant='outlined'
//                 color='primary'
//                 onClick={downloadChapterCards}
//               >
//                 <GetAppIcon /> Download
//               </Button>
//             </Grid>
//           </Grid>
//           <DialogContentText>
//             Download Cards. Since we have more than {props.count} entries you
//             can download them in batches
//           </DialogContentText>
//           {Array(Math.ceil(props.count / 10000))
//             .fill(null)
//             .map((value, idx) => (
//               <Button
//                 style={{ margin: '3px' }}
//                 variant='outlined'
//                 onClick={() => {
//                   props.downloadCard(idx * 10000, (idx + 1) * 10000)
//                 }}
//               >
//                 <GetAppIcon /> Download Entries between ({idx * 10000 + 1} and{' '}
//                 {(idx + 1) * 1000})
//               </Button>
//             ))}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color='primary'>
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   )
// }

// function DownloadCertificatesDialog(props) {
//   const classes = useStyles() 
//   const [open, setOpen] = React.useState(false)
//   // const [open, setOpen] = React.useState(false)
//   const [chapterInputs, setChapterInputs] = useState({})
//   const handleChapterInputChange = (e) => {
//     setChapterInputs({ ...chapterInputs, [e.target.name]: e.target.value })
//   }
//   const downloadChapterCertificates = async () => {
//     try {
//       axios({
//         url: `${BASE_URL}/api/student-membership/members/download-certificates`, //your url
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem('profile'))?.token
//           }`,
//         },
//         params: { ...chapterInputs },
//         responseType: 'blob', // important
//       }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = url
//         link.setAttribute(
//           'download',
//           `Student Cards - downloaded at ${moment().format(
//             'YYYY-MM-DD-HH-MM-SS'
//           )}.pdf`
//         ) //or any other extension
//         document.body.appendChild(link)
//         link.click()
//       })
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   const handleClickOpen = () => {
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }

//   return (
//     <div>
//       <Button variant='contained' color='primary' onClick={handleClickOpen} className={classes.downloadButton}>
//         <GetAppIcon /> Download Certificates
//       </Button>

//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby='form-dialog-title'
//       >
//         <DialogTitle id='form-dialog-title'>
//           Download Certificates
//           <br />
//           Total Count : {props.count}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>Download Certificates by Chapter Code</DialogContentText>
//           <Grid container spacing={3}>
//             <Grid item xs={12}>
//               <TextField
//                 name='chapter_code'
//                 label='Chapter Code'
//                 variant='outlined'
//                 onChange={handleChapterInputChange}
//                 size='small'
//                 required
//               ></TextField>
//             </Grid>
//             <Grid item>
//               <TextField
//                 name='startMembershipNo'
//                 label='Start Membership No'
//                 variant='outlined'
//                 size='small'
//                 onChange={handleChapterInputChange}
//                 required
//               ></TextField>
//             </Grid>
//             <Grid item>
//               <TextField
//                 name='endMembershipNo'
//                 label='End Membership No'
//                 variant='outlined'
//                 size='small'
//                 onChange={handleChapterInputChange}
//                 required
//               ></TextField>
//             </Grid>
//             <Grid item>
//               <Button
//                 style={{ margin: '3px' }}
//                 variant='outlined'
//                 color='primary'
//                 onClick={downloadChapterCertificates}
//               >
//                 <GetAppIcon /> Download
//               </Button>
//             </Grid>
//           </Grid>
//           <DialogContentText>
//             Download Certificates. Since we have more than {props.count} entries you
//             can download them in batches
//           </DialogContentText>
//           {Array(Math.ceil(props.count / 10000))
//             .fill(null)
//             .map((_, idx) => (
//               <Button
//               key = {idx}
//               className={classes.dialogButton}
//                 variant='outlined'
//                 onClick={() => {
//                   props.downloadCertificates(idx * 10000, (idx + 1) * 10000)
//                 }}
//               >
//                 <GetAppIcon /> Download Entries between ({idx * 10000 + 1} -{' '}
//                 {(idx + 1) * 1000})
//               </Button>
//             ))}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color='primary'>
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   )
// }

// export default function StudentMembersTable() {
//   const classes = useStyles()
//   let { page } = useParams()
//   const [loading, setLoading] = useState(true)
//   let { path, url } = useRouteMatch()
//   const [studentMembers, setStudentMembers] = useState([])
//   const [next, setNextPage] = useState(null)
//   const [prev, setPrevPage] = useState(null)
//   const [count, setCount] = useState(0)
//   const [searchParameters, setSearchParameters] = useState({
//     membership_status: 'member',
//   })

//   const handleLoading = (value) => {
//     setLoading(value)
//   }
//   const handleChange = (e) => {
//     setSearchParameters({
//       ...searchParameters,
//       [e.target.name]: e.target.value,
//     })
//   }
//   const searchMembers = () => {
//     getData()
//   }
//   const prevPage = (val) => {
//     setPrevPage(val)
//   }
//   const nextPage = (val) => {
//     setNextPage(val)
//   }
//   const getData = async () => {
//     setLoading(true)
//     const data = await getStudentMembers({
//       page: page,
//       limit: 100,
//       membership_status: 'member',
//       ...searchParameters,
//     })
//     if (data?.data?.result) {
//       setStudentMembers(data?.data?.result?.results)
//       setCount(data?.data?.result?.count)
//       nextPage(data?.data?.result?.next?.page)
//       prevPage(data?.data?.result?.previous?.page)
//       handleLoading(false)
//     }
//     // setSearchParameters({});
//   }
//   const generateReport = async (start, end) => {
//     try {
//       axios({
//         url: `${BASE_URL}/api/student-membership/members/report`, //your url
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem('profile'))?.token
//           }`,
//         },
//         params: { ...searchParameters, start, end },
//         responseType: 'blob', // important
//       }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = url
//         link.setAttribute(
//           'download',
//           `Student Membership Report - exported at ${moment().format(
//             'YYYY-MM-DD-HH-MM-SS'
//           )}.xlsx`
//         ) //or any other extension
//         document.body.appendChild(link)
//         link.click()
//       })
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   const downloadCard = async (start, end) => {
//     try {
//       axios({
//         url: `${BASE_URL}/api/student-membership/members/download-cards`, //your url
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem('profile'))?.token
//           }`,
//         },
//         params: { ...searchParameters, start, end },
//         responseType: 'blob', // important
//       }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = url
//         link.setAttribute(
//           'download',
//           `Student Card - downloaded at ${moment().format(
//             'YYYY-MM-DD-HH-MM-SS'
//           )}.pdf`
//         ) //or any other extension
//         document.body.appendChild(link)
//         link.click()
//       })
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   const downloadCertificates = async (start, end) => {
//     try {
//       const response = await axios({
//         url: `${BASE_URL}/api/student-membership/members/download-certificates`,
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem('profile'))?.token
//           }`,
//         },
//         params: { ...searchParameters, start, end },
//         responseType: 'blob',
//       })
//       console.log('Response Data:', response)
//       // const url = window.URL.createObjectURL(new Blob([response.data]))
//       const url = window.URL.createObjectURL(
//         new Blob([response.data], { type: 'application/pdf' })
//       )
//       const link = document.createElement('a')
//       link.href = url
//       link.setAttribute(
//         'download',
//         `SM Certificates - ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`
//       )
//       document.body.appendChild(link)
//       link.click()
//       document.body.removeChild(link)
//     } catch (error) {
//       console.error('Download Error:', error)
//     }
//   }

//   useEffect(() => {
//     getData()
//   }, [page])


//   return (
//     <Paper className={classes.root} elevation={5}>
//       {loading ? (
//         <div align='center' className={classes.loading}>
//           <CircularProgress />
//         </div>
//       ) : (
//         <>
//           <Box elevation={2} className={classes.box}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={12} lg={12}>
//                 <Typography variant='h6'>Total Count : {count}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={4}>
//                 <TextField
//                   label='Full Name'
//                   variant='outlined'
//                   name='fullname'
//                   onChange={handleChange}
//                   value={searchParameters.fullname}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={4}>
//                 <TextField
//                   label='Institute Membership No'
//                   variant='outlined'
//                   name='instituteMembershipNo'
//                   onChange={handleChange}
//                   value={searchParameters.instituteMembershipNo}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={4}>
//                 <TextField
//                   label='Institute Name'
//                   variant='outlined'
//                   name='institutionName'
//                   onChange={handleChange}
//                   value={searchParameters.institutionName}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={4}>
//                 <TextField
//                   label='Institute State'
//                   variant='outlined'
//                   name='institutionState'
//                   onChange={handleChange}
//                   value={searchParameters.institutionState}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={2}>
//                 <TextField
//                   label='Section Code'
//                   variant='outlined'
//                   name='section_code'
//                   onChange={handleChange}
//                   value={searchParameters.section_code}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={2}>
//                 <TextField
//                   label='Chapter Code'
//                   variant='outlined'
//                   name='chapter_code'
//                   onChange={handleChange}
//                   value={searchParameters.chapter_code}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={2}>
//                 <TextField
//                   label='Pincode'
//                   variant='outlined'
//                   name='institute_pin'
//                   onChange={handleChange}
//                   value={searchParameters.institute_pin}
//                   fullWidth
//                 ></TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={2}>
//                 <TextField
//                   label='Duration'
//                   variant='outlined'
//                   name='duration'
//                   onChange={handleChange}
//                   value={searchParameters.duration}
//                   fullWidth
//                   select
//                 >
//                   {[1, 2, 3, 4, 5].map((value) => (
//                     <MenuItem key={value} value={value}>
//                       {value}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={2}>
//                 <TextField
//                   label='Membership Status'
//                   variant='outlined'
//                   name='membership_status'
//                   onChange={handleChange}
//                   fullWidth
//                   value={searchParameters.membership_status || 'member'}
//                   select
//                 >
//                   {['inprogress', 'approved', 'member', 'rejected'].map(
//                     (value) => (
//                       <MenuItem key={value} value={value}>
//                         {value.toLocaleUpperCase()}
//                       </MenuItem>
//                     )
//                   )}
//                 </TextField>
//               </Grid>
//               <Grid item xs={12} sm={12} lg={3}>
//                 <KeyboardDateTimePicker
//                   autoOk
//                   variant='inline'
//                   inputVariant='outlined'
//                   label='From'
//                   format='dd/MM/yyyy'
//                   name='startDate'
//                   fullWidth
//                   value={searchParameters.startDate}
//                   InputAdornmentProps={{ position: 'start' }}
//                   onChange={(date) => {
//                     setSearchParameters({
//                       ...searchParameters,
//                       startDate: date,
//                     })
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} lg={3}>
//                 <KeyboardDateTimePicker
//                   autoOk
//                   variant='inline'
//                   inputVariant='outlined'
//                   label='To'
//                   name='endDate'
//                   format='dd/MM/yyyy'
//                   fullWidth
//                   value={searchParameters.endDate}
//                   InputAdornmentProps={{ position: 'start' }}
//                   onChange={(date) => {
//                     setSearchParameters({ ...searchParameters, endDate: date })
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} lg={12} align='center'>
//                 <Button
//                   variant='contained'
//                   color='primary'
//                   className={classes.searchButton}
//                   onClick={searchMembers}
//                 >
//                   <SearchIcon /> Search
//                 </Button>
//               </Grid>
//               {/*                
//                 <Button 
//                   variant="contained" 
//                   color="primary" 
//                   onClick={generateReport}
//                   className={classes.buttonPadding}
//                 >
//                   <AssessmentIcon/> Generate Report
//                 </Button> */}

//               <Grid item xs={12} sm={12} lg={3}>
//                 <GenerateReportDialog
//                   count={count}
//                   searchParameters={searchParameters}
//                   generateReport={generateReport}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} lg={3}>
//                 <DownloadCardDialog
//                   count={count}
//                   searchParameters={searchParameters}
//                   downloadCard={downloadCard}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} lg={3}>
//                 <DownloadCertificatesDialog
//                   count={count}
//                   searchParameters={searchParameters}
//                   downloadCertificates={downloadCertificates}
//                   className={classes.downloadButton} // Apply custom button styles here
//                 />
//               </Grid>
//             </Grid>
//             {/* <Button 
//                   variant="outlined" 
//                   color="primary" 
//                   className={classes.searchButton}
//                   onClick={()=>{
//                     setSearchParameters({})
//                   }}
//                 >
//                   <ClearIcon/> Clear
//                 </Button> */}
//           </Box>
//           <TableContainer className={classes.container}>
//             <Table stickyHeader aria-label='sticky table'>
//               <TableHead>
//                 <TableRow>
//                   {columns.map((column) => (
//                     <TableCell
//                       key={column.id}
//                       align={column.align}
//                       style={{ minWidth: column.minWidth }}
//                     >
//                       {column.label}
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {studentMembers.map((row) => {
//                   return (
//                     <TableRow key={row._id} hover role='checkbox' tabIndex={-1}>
//                       <TableCell>{row.chapter_code}</TableCell>
//                       <TableCell component='th' scope='row'>
//                         {row.membership_no}
//                       </TableCell>
//                       <TableCell>{row.section_code}</TableCell>
//                       <TableCell component='th' scope='row'>
//                         {row.fullname}
//                       </TableCell>
//                       <TableCell component='th' scope='row'>
//                         {row.institute_name}
//                       </TableCell>
//                       <TableCell>{row.institute_state}</TableCell>
//                       {/* <TableCell>
//                       {row.email} 
//                     </TableCell> */}
//                       <TableCell>{row.duration} year(s)</TableCell>
//                       {/* <TableCell>
//                       {row.mobile_no}
//                     </TableCell> */}
//                       {/* <TableCell>
//                       {row.gender}
//                     </TableCell> */}
//                       <TableCell>
//                         <StudentMemberFormDialog value={row} />
//                       </TableCell>
//                     </TableRow>
//                   )
//                 })}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <Box align='right'>
//             <Button to={`${prev}`} component={Link} disabled={!prev}>
//               <NavigateBeforeIcon />
//             </Button>
//             <Button to={`${next}`} component={Link} disabled={!next}>
//               <NavigateNextIcon />
//             </Button>
//           </Box>
//         </>
//       )}
//     </Paper>
//   )
// }
import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { Collapse, Box, Grid, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from '@material-ui/core'
import { Link, useRouteMatch, useParams } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import GetAppIcon from '@material-ui/icons/GetApp'
import ClearIcon from '@material-ui/icons/Clear'

import AssessmentIcon from '@material-ui/icons/Assessment'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import axios from 'axios'
import { MenuItem, Typography } from '@material-ui/core'

import useStyles from './styles'
import { getStudentMembers } from '../../api'
import BASE_URL from '../../api/constants'
import StudentMemberFormDialog from '../StudentMemberFormDialog/StudentMemberFormDialog'

const columns = [
  { id: 'chapterCode', label: 'Chapter Code' },
  { id: 'membershipNo', label: 'Membership No' },
  { id: 'sectionCode', label: 'Section Code' },
  { id: 'name', label: 'Full Name' },
  { id: 'instituteName', label: 'Institution Name' },
  { id: 'state', label: 'Institute State' },
  // { id: 'email', label: 'Email' },
  { id: 'duration', label: 'Duration' },
  // { id: 'branch', label: 'Branch' },
  // { id: 'mobile_no', label: 'Mobile No' },
  // { id: 'instituteId', label: 'Institute Id' },
  // { id: 'gender', label: 'Gender' },
  { id: 'view', label: '' },
]

function GenerateReportDialog(props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        <AssessmentIcon /> Generate Report
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Generate Report
          <br />
          Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Download Data in the .xlsx format. Since we have more than{' '}
            {props.count} entries you can download them in batches
          </DialogContentText>
          {Array(Math.ceil(props.count / 100000))
            .fill(null)
            .map((value, idx) => (
              <Button
                style={{ margin: '2px' }}
                variant='outlined'
                onClick={() => {
                  props.generateReport(idx * 100000, (idx + 1) * 100000)
                }}
              >
                <GetAppIcon /> Download Entries between ({idx * 100000 + 1} and{' '}
                {(idx + 1) * 100000})
              </Button>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
function DownloadCardDialog(props) {
  const [open, setOpen] = React.useState(false)
  const [chapterInputs, setChapterInputs] = useState({})
  const handleChapterInputChange = (e) => {
    setChapterInputs({ ...chapterInputs, [e.target.name]: e.target.value })
  }
  const downloadChapterCards = async () => {
    try {
      axios({
        url: `${BASE_URL}/api/student-membership/members/download-cards`, //your url
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { ...chapterInputs },
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Student Cards - downloaded at ${moment().format(
            'YYYY-MM-DD-HH-MM-SS'
          )}.pdf`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyles() // Use your styles
  return (
    <div>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        <GetAppIcon /> Download Cards
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Download Cards
          <br />
          Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Download Cards by Chapter Code</DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name='chapter_code'
                label='Chapter Code'
                variant='outlined'
                onChange={handleChapterInputChange}
                size='small'
                required
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                name='startMembershipNo'
                label='Start Membership No'
                variant='outlined'
                size='small'
                onChange={handleChapterInputChange}
                required
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                name='endMembershipNo'
                label='End Membership No'
                variant='outlined'
                size='small'
                onChange={handleChapterInputChange}
                required
              ></TextField>
            </Grid>
            <Grid item>
              <Button
                style={{ margin: '3px' }}
                variant='outlined'
                color='primary'
                onClick={downloadChapterCards}
              >
                <GetAppIcon /> Download
              </Button>
            </Grid>
          </Grid>
          <DialogContentText>
            Download Cards. Since we have more than {props.count} entries you
            can download them in batches
          </DialogContentText>
          {Array(Math.ceil(props.count / 10000))
            .fill(null)
            .map((value, idx) => (
              <Button
                style={{ margin: '3px' }}
                variant='outlined'
                onClick={() => {
                  props.downloadCard(idx * 10000, (idx + 1) * 10000)
                }}
              >
                <GetAppIcon /> Download Entries between ({idx * 10000 + 1} and{' '}
                {(idx + 1) * 1000})
              </Button>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function DownloadCertificatesDialog(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  // const [open, setOpen] = React.useState(false)
  const [chapterInputs, setChapterInputs] = useState({})
  const handleChapterInputChange = (e) => {
    setChapterInputs({ ...chapterInputs, [e.target.name]: e.target.value })
  }
  const downloadChapterCertificates = async () => {
    try {
      axios({
        url: `${BASE_URL}/api/student-membership/members/download-certificates`, //your url
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { ...chapterInputs },
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Student Certificates - downloaded at ${moment().format(
            'YYYY-MM-DD-HH-MM-SS'
          )}.pdf`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        <GetAppIcon /> Download Certificates
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Download Certificates
          <br />
          Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Download Certificates by Chapter Code
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name='chapter_code'
                label='Chapter Code'
                variant='outlined'
                onChange={handleChapterInputChange}
                size='small'
                required
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                name='startMembershipNo'
                label='Start Membership No'
                variant='outlined'
                size='small'
                onChange={handleChapterInputChange}
                required
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                name='endMembershipNo'
                label='End Membership No'
                variant='outlined'
                size='small'
                onChange={handleChapterInputChange}
                required
              ></TextField>
            </Grid>
            <Grid item>
              <Button
                style={{ margin: '3px' }}
                variant='outlined'
                color='primary'
                onClick={downloadChapterCertificates}
              >
                <GetAppIcon /> Download
              </Button>
            </Grid>
          </Grid>
          <DialogContentText>
            Download Certificates. Since we have more than {props.count} entries
            you can download them in batches
          </DialogContentText>
          {Array(Math.ceil(props.count / 10000))
            .fill(null)
            .map((value, idx) => (
              <Button
                style={{ margin: '3px' }}
                variant='outlined'
                onClick={() => {
                  props.downloadCertificates(idx * 10000, (idx + 1) * 10000)
                }}
              >
                <GetAppIcon /> Download Entries between ({idx * 10000 + 1} and{' '}
                {(idx + 1) * 1000})
              </Button>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default function StudentMembersTable() {
  const classes = useStyles()
  let { page } = useParams()
  const [loading, setLoading] = useState(true)
  let { path, url } = useRouteMatch()
  const [studentMembers, setStudentMembers] = useState([])
  const [next, setNextPage] = useState(null)
  const [prev, setPrevPage] = useState(null)
  const [count, setCount] = useState(0)
  const [searchParameters, setSearchParameters] = useState({
    membership_status: 'member',
  })

  const handleLoading = (value) => {
    setLoading(value)
  }
  const handleChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      [e.target.name]: e.target.value,
    })
  }
  const searchMembers = () => {
    getData()
  }
  const prevPage = (val) => {
    setPrevPage(val)
  }
  const nextPage = (val) => {
    setNextPage(val)
  }
  const getData = async () => {
    setLoading(true)
    const data = await getStudentMembers({
      page: page,
      limit: 100,
      membership_status: 'member',
      ...searchParameters,
    })
    if (data?.data?.result) {
      setStudentMembers(data?.data?.result?.results)
      setCount(data?.data?.result?.count)
      nextPage(data?.data?.result?.next?.page)
      prevPage(data?.data?.result?.previous?.page)
      handleLoading(false)
    }
    // setSearchParameters({});
  }
  const generateReport = async (start, end) => {
    try {
      axios({
        url: `${BASE_URL}/api/student-membership/members/report`, //your url
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { ...searchParameters, start, end },
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Student Membership Report - exported at ${moment().format(
            'YYYY-MM-DD-HH-MM-SS'
          )}.xlsx`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }
  const downloadCard = async (start, end) => {
    try {
      axios({
        url: `${BASE_URL}/api/student-membership/members/download-cards`, //your url
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { ...searchParameters, start, end },
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Student Card - downloaded at ${moment().format(
            'YYYY-MM-DD-HH-MM-SS'
          )}.pdf`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }

  const downloadCertificates = async (start, end) => {
    try {
      const response = await axios({
        url: `${BASE_URL}/api/student-membership/members/download-certificates`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { ...searchParameters, start, end },
        responseType: 'blob',
      })
      console.log('Response Data:', response)
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `SM Certificates - ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Download Error:', error)
    }
  }

  useEffect(() => {
    getData()
  }, [page])

  return (
    <Paper className={classes.root} elevation={5}>
      {loading ? (
        <div align='center' className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box elevation={2} className={classes.box}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant='h6'>Total Count : {count}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  label='Full Name'
                  variant='outlined'
                  name='fullname'
                  onChange={handleChange}
                  value={searchParameters.fullname}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  label='Institute Membership No'
                  variant='outlined'
                  name='instituteMembershipNo'
                  onChange={handleChange}
                  value={searchParameters.instituteMembershipNo}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  label='Institute Name'
                  variant='outlined'
                  name='institutionName'
                  onChange={handleChange}
                  value={searchParameters.institutionName}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  label='Institute State'
                  variant='outlined'
                  name='institutionState'
                  onChange={handleChange}
                  value={searchParameters.institutionState}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={2}>
                <TextField
                  label='Section Code'
                  variant='outlined'
                  name='section_code'
                  onChange={handleChange}
                  value={searchParameters.section_code}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={2}>
                <TextField
                  label='Chapter Code'
                  variant='outlined'
                  name='chapter_code'
                  onChange={handleChange}
                  value={searchParameters.chapter_code}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={2}>
                <TextField
                  label='Pincode'
                  variant='outlined'
                  name='institute_pin'
                  onChange={handleChange}
                  value={searchParameters.institute_pin}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={2}>
                <TextField
                  label='Duration'
                  variant='outlined'
                  name='duration'
                  onChange={handleChange}
                  value={searchParameters.duration}
                  fullWidth
                  select
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={2}>
                <TextField
                  label='Membership Status'
                  variant='outlined'
                  name='membership_status'
                  onChange={handleChange}
                  fullWidth
                  value={searchParameters.membership_status || 'member'}
                  select
                >
                  {['inprogress', 'approved', 'member', 'rejected'].map(
                    (value) => (
                      <MenuItem key={value} value={value}>
                        {value.toLocaleUpperCase()}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant='inline'
                  inputVariant='outlined'
                  label='From'
                  format='dd/MM/yyyy'
                  name='startDate'
                  fullWidth
                  value={searchParameters.startDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => {
                    setSearchParameters({
                      ...searchParameters,
                      startDate: date,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant='inline'
                  inputVariant='outlined'
                  label='To'
                  name='endDate'
                  format='dd/MM/yyyy'
                  fullWidth
                  value={searchParameters.endDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => {
                    setSearchParameters({ ...searchParameters, endDate: date })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} align='center'>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.searchButton}
                  onClick={searchMembers}
                >
                  <SearchIcon /> Search
                </Button>
              </Grid>
              {/*                
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={generateReport}
                  className={classes.buttonPadding}
                >
                  <AssessmentIcon/> Generate Report
                </Button> */}

              <Grid item xs={12} sm={12} lg={3}>
                <GenerateReportDialog
                  count={count}
                  searchParameters={searchParameters}
                  generateReport={generateReport}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <DownloadCardDialog
                  count={count}
                  searchParameters={searchParameters}
                  downloadCard={downloadCard}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <DownloadCertificatesDialog
                  count={count}
                  searchParameters={searchParameters}
                  downloadCertificates={downloadCertificates}
                  className={classes.downloadButton} // Apply custom button styles here
                />
              </Grid>
            </Grid>
            {/* <Button 
                  variant="outlined" 
                  color="primary" 
                  className={classes.searchButton}
                  onClick={()=>{
                    setSearchParameters({})
                  }}
                >
                  <ClearIcon/> Clear
                </Button> */}
          </Box>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {studentMembers.map((row) => {
                  return (
                    <TableRow key={row._id} hover role='checkbox' tabIndex={-1}>
                      <TableCell>{row.chapter_code}</TableCell>
                      <TableCell component='th' scope='row'>
                        {row.membership_no}
                      </TableCell>
                      <TableCell>{row.section_code}</TableCell>
                      <TableCell component='th' scope='row'>
                        {row.fullname}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.institute_name}
                      </TableCell>
                      <TableCell>{row.institute_state}</TableCell>
                      {/* <TableCell>
                      {row.email} 
                    </TableCell> */}
                      <TableCell>{row.duration} year(s)</TableCell>
                      {/* <TableCell>
                      {row.mobile_no}
                    </TableCell> */}
                      {/* <TableCell>
                      {row.gender}
                    </TableCell> */}
                      <TableCell>
                        <StudentMemberFormDialog value={row} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box align='right'>
            <Button to={`${prev}`} component={Link} disabled={!prev}>
              <NavigateBeforeIcon />
            </Button>
            <Button to={`${next}`} component={Link} disabled={!next}>
              <NavigateNextIcon />
            </Button>
          </Box>
        </>
      )}
    </Paper>
  )
}